const ww = window.wx

import {
  getWechatSignature,
  getWechatAgenSignature
} from '@/service/wechat.service.js'
import { jsApiList, wxShareInfo } from './constants'

//所有需要使用JS-SDK的页面必须先注入配置信息，否则将无法调用
//同一个url仅需调用一次。
export async function wechatConfig(callBack) {
  const { data } = await getWechatSignature({
    apis: jsApiList,
    open_tag_list: [],
    url: location.href,
    debug: true,
    beta: true,
    type: 'enterprise',
    chain_corp_id: localStorage.getItem('chain_corp_id')
  })
  const { appId, nonceStr, timestamp, signature } = data
  console.log('appid:', appId, 'nonceStr:', nonceStr)
  ww.config({
    beta: true, // 必须这么写，否则ww.invoke调用形式的jsapi会有问题
    // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    appId, // 必填，企业微信的corpID
    timestamp, // 必填，生成签名的时间戳
    nonceStr, // 必填，生成签名的随机串
    signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
    jsApiList // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
  })
  ww.ready(() => {
    if (callBack) {
      callBack(ww)
    }
    // wechatShareConfig()
  })
  ww.error((res) => {
    console.log('微信注册失败：' + res)
  })
}
// https://work.weixin.qq.com/api/doc/90001/90144/90548
export async function wechatAgentConfig(callBack) {
  // const { data } = await getWechatAgenSignature({
  //   ticket_type: 2,
  //   url: location.href
  // })
  const res = await getWechatSignature({
    apis: jsApiList,
    open_tag_list: [],
    url: location.href,
    debug: true,
    beta: true,
    type: 'aggent',
    chain_corp_id: localStorage.getItem('chain_corp_id')
  })
  const { corpid, agentid, nonceStr, signature, timestamp } = res.data
  wx.agentConfig({
    beta: true, // 必须这么写，否则ww.invoke调用形式的jsapi会有问题
    // debug: true, //
    corpid: corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
    agentid: agentid, // 必填，企业微信的应用id （e.g. 1000247）
    timestamp: timestamp, // 必填，生成签名的时间戳
    nonceStr: nonceStr, // 必填，生成签名的随机串
    signature: signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
    jsApiList: jsApiList, //必填
    success: function (res) {
      console.log(res)
      callBack(res)
    },
    fail: function (res) {
      if (res.errMsg.indexOf('function not exist') > -1) {
        alert('版本过低请升级')
      }
    }
  })
}

// https://work.weixin.qq.com/api/doc/90000/90136/93555
// 将H5页面通过个人群发发送给客户
export function shareToExternalContact({
  title = '',
  desc = '',
  link = '',
  imgUrl = ''
}) {
  return new Promise((resolve, reject) => {
    const data = {
      title, // 消息的标题
      desc, // 消息的描述
      link, // 消息链接
      imgUrl // 消息封面
    }
    console.log('群发data:', data)

    wechatConfig((wx) => {
      wechatAgentConfig(() => {
        wx.invoke('shareToExternalContact', data, (res) => {
          console.log('JSON.stringify(res):', JSON.stringify(res))
          console.log('res.err_msg:', res.err_msg)
          if (res.err_msg == 'shareToExternalContact:ok') {
            resolve(res)
          } else {
            reject(res)
          }
        })
      })
    })
  })
}

// https://work.weixin.qq.com/api/doc/90001/90144/93563
// 将H5页面通过群发助手发送给客户群
export function shareToExternalChat({
  title = '',
  desc = '',
  link = '',
  imgUrl = ''
}) {
  return new Promise((resolve, reject) => {
    const data = {
      title, // 消息的标题
      desc, // 消息的描述
      link, // 消息链接
      imgUrl // 消息封面
    }
    console.log('群发data:', data)

    wechatConfig((wx) => {
      wechatAgentConfig(() => {
        wx.invoke('shareToExternalChat', data, (res) => {
          console.log('JSON.stringify(res):', JSON.stringify(res))
          console.log('res.err_msg:', res.err_msg)
          if (res.err_msg == 'shareToExternalChat:ok') {
            resolve(res)
          } else {
            reject(res)
          }
        })
      })
    })
  })
}

// https://work.weixin.qq.com/api/doc/90000/90136/90490#自定义转发到会话
// 自定义转发到会话
export function shareAppMessage(data) {
  return new Promise((resolve, reject) => {
    // const data = {
    //   title, // 消息的标题
    //   desc, // 消息的描述
    //   link, // 消息链接
    //   imgUrl // 消息封面
    // }

    wechatConfig((wx) => {
      wx.invoke('shareAppMessage', data, (res) => {
        if (res.err_msg == 'shareAppMessage:ok') {
          resolve(res)
        } else {
          reject(res)
        }
      })
    })
  })
}

// https://work.weixin.qq.com/api/doc/90000/90136/90502
// 设置系统剪贴板的内容
export function setClipboardData(text) {
  return new Promise((resolve, reject) => {
    wechatConfig((wx) => {
      wx.setClipboardData({
        data: String(text),
        success: resolve,
        fail: reject
      })
    })
  })
}

// https://work.weixin.qq.com/api/doc/90001/90144/93231
// 打开会话
export function openEnterpriseChat({
  userIds,
  externalUserIds,
  groupName,
  chatId
}) {
  console.log('打开会话')
  return new Promise((resolve, reject) => {
    wechatConfig((wx) => {
      // wechatAgentConfig(() => {
      wx.openEnterpriseChat(
        Object.assign(
          {
            userIds,
            externalUserIds,
            groupName,
            chatId
          },
          {
            success: resolve,
            fail: reject
          }
        )
      )
      // })
    })
  })
}

// 选人
export function selectEnterpriseContact({
  fromDepartmentId,
  mode,
  type,
  selectedDepartmentIds,
  selectedUserIds
}) {
  return new Promise((resolve, reject) => {
    wechatConfig((wx) => {
      wx.invoke(
        'selectEnterpriseContact',
        {
          fromDepartmentId,
          mode,
          type,
          selectedDepartmentIds,
          selectedUserIds
        },
        function (res) {
          if (res.err_msg == 'selectEnterpriseContact:ok') {
            if (typeof res.result == 'string') {
              res.result = JSON.parse(res.result)
            }
            resolve(res)
          } else {
            reject(res)
          }
        }
      )
    })
  })
}

// https://work.weixin.qq.com/api/doc/90001/90144/91823
// 外部联系人选人接口
export function selectExternalContact() {
  return new Promise((resolve, reject) => {
    wechatConfig((wx) => {
      wechatAgentConfig(() => {
        wx.invoke(
          'selectExternalContact',
          Object.assign({ filterType: 0 }),
          function (res) {
            console.log(res.err_msg)
            if (res.err_msg == 'selectExternalContact:ok') {
              // res.userIds
              resolve(res)
            } else {
              reject(res)
            }
          }
        )
      })
    })
  })
}

// https://work.weixin.qq.com/api/doc/90000/90136/92722
// 获取进入H5页面的入口环境
export function getContext() {
  return new Promise((resolve, reject) => {
    wechatConfig((wx) => {
      wechatAgentConfig(() => {
        wx.invoke('getContext', {}, function (res) {
          if (res.err_msg == 'getContext:ok') {
            resolve(res.entry)
          } else {
            reject(res)
          }
        })
      })
    })
  })
}

// https://work.weixin.qq.com/api/doc/90000/90136/92095
// 获取当前客户群的群ID
export function getCurExternalChat() {
  return new Promise((resolve, reject) => {
    wechatConfig((wx) => {
      wechatAgentConfig(() => {
        wx.invoke('getCurExternalChat', {}, function (res) {
          if (res.err_msg == 'getCurExternalChat:ok') {
            resolve(res.chatId) //返回当前客户群的群聊ID
          } else {
            reject(res) //错误处理
          }
        })
      })
    })
  })
}

// https://work.weixin.qq.com/api/doc/90000/90136/91799
// 获取当前外部联系人userid
export function getCurExternalContact() {
  return new Promise((resolve, reject) => {
    wechatConfig((wx) => {
      wechatAgentConfig(() => {
        wx.invoke('getCurExternalContact', {}, function (res) {
          if (res.err_msg == 'getCurExternalContact:ok') {
            resolve(res.userId) //返回当前外部联系人userId
          } else {
            reject(res) //错误处理
          }
        })
      })
    })
  })
}

// https://work.weixin.qq.com/api/doc/90000/90136/91789
// 聊天工具栏分享消息到会话
export function sendChatMessage(content) {
  return new Promise((resolve, reject) => {
    wechatConfig((wx) => {
      wechatAgentConfig(() => {
        wx.invoke(
          'sendChatMessage',
          { msgtype: 'text', text: { content } },
          function (res) {
            if (res.err_msg === 'sendChatMessage:ok') {
              resolve(res)
            } else {
              reject(res)
            }
          }
        )
      })
    })
  })
}

function wechatShareConfig() {
  // 转发
  ww.onMenuShareAppMessage({
    title: wxShareInfo.title,
    desc: wxShareInfo.desc,
    link: wxShareInfo.link,
    imgUrl: wxShareInfo.imgUrl,
    type: wxShareInfo.type,
    dataUrl: wxShareInfo.dataUrl,
    success: function () {
      // 用户确认分享后执行的回调函数
    },
    cancel: function () {
      // 用户取消分享后执行的回调函数
    }
  })
  // 分享到微信
  ww.onMenuShareWechat({
    title: wxShareInfo.title,
    link: wxShareInfo.link,
    imgUrl: wxShareInfo.imgUrl,
    success: function () {
      // 用户确认分享后执行的回调函数
    },
    cancel: function () {
      // 用户取消分享后执行的回调函数
    }
  })
  //分享到朋友圈
  ww.onMenuShareTimeline({
    title: wxShareInfo.title,
    link: wxShareInfo.link,
    imgUrl: wxShareInfo.imgUrl,
    success: function () {
      // 用户确认分享后执行的回调函数
    },
    cancel: function () {
      // 用户取消分享后执行的回调函数
    }
  })
}
